<template>
    <Dialog header="Add Collaborative Paper Trading Account" v-model:visible="display" :modal="true" :closable="true" :draggable="false"
        class="add-collaborative-portfolio-account-modal">

        <div class='nickname-input-container'>
            <div :class="{ 'p-float-label': true, 'input-error': v$.nickname.$error }">
                <InputText id='nicknameInput' type="text" v-model='v$.nickname.$model' @input="resetError('nickname')" />
                <label for='nicknameInput' class='input-label'>Enter in a nickname</label>
            </div>
            <template v-for="error of v$.nickname.$errors" :key="error.$uid">
                <span class='error-message'>{{ error.$message }}</span>
            </template>
        </div>
        <div class='currency-input-container'>
            <div :class="{ 'p-float-label': true }">
                <Dropdown id="currencySelector" class="currency-selector" v-model="defaultCurrencyId" :options="currencyOptions" optionLabel="label"
                    optionValue="value">
                </Dropdown>
                <label for='currencySelector' class='input-label'>Select a currency</label>
            </div>
        </div>

        

        <template #footer>
            <Button class='p-button-raised p-button-rounded green-button__primary' label='Add' @click='addAccount' />
            <Button class='p-button-raised p-button-rounded red-button__primary' label='Cancel' @click='close' />
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';

import GroupService from '../../../service/GroupService';

import EventBus from '../../../event-bus';
import { mapState } from 'vuex';
import useVuelidate from '@vuelidate/core';
import { maxLength } from '@vuelidate/validators';

import { CANADIAN_CURRENCY_TYPE, US_CURRENCY_TYPE } from '../../../common/constants';

const currencyOptions = [
    { label: CANADIAN_CURRENCY_TYPE.SYMBOL, value: CANADIAN_CURRENCY_TYPE.ID },
    { label: US_CURRENCY_TYPE.SYMBOL, value: US_CURRENCY_TYPE.ID }
];

export default {
    name: 'AddCollaborativePortfolioAccountModal',
    setup: () => ({ v$: useVuelidate() }),
    components: {
        Dialog, Dropdown, InputText
    },
    data() {
        return {
            currencyOptions,

            display: false,
            callback: null,
            portfolio: null,
            nickname: '',
            defaultCurrencyId: CANADIAN_CURRENCY_TYPE.ID,
            qualifiedAccountsList: []
        };
    },

    computed: {
        ...mapState(['selectedGroup']),
    },

    methods: {
        resetError(field) {
            this.v$[field].$reset();
        },

        open(data) {
            this.v$.$reset();
            this.callback = data.callback;
            this.portfolio = data.portfolio;
            this.display = true;
            this.nickname = '';
        },

        close() {
            this.display = false;
        },

        addAccount() {
            GroupService.addGroupCollaborativePortfolioAccount(
                this.selectedGroup.groupId, this.portfolio.groupPortfolioId, this.nickname, this.defaultCurrencyId
            ).then(resp => {
                this.display = false;
                if (resp.data.status == 'success') {
                    this.$toast.add({ severity: 'info', summary: "Account Added.", detail: '', life: 4000, group: 'center' });
                    EventBus.emit('refresh-portfolio-overview');
                    EventBus.emit('track-adding-account-to-group-portfolio');
                    this.callback();
                } else {
                    this.$toast.add({ severity: 'error', summary: "Oops! Account not added.  If the problem persists contact support@invrs.com.", detail: '', life: 4000, group: 'center' });
                }
            });
        },
    },

    validations() {
        return {
            nickname: {
                maxLength: maxLength(50)
            },
        }
    }
};
</script>

<style>
.add-collaborative-portfolio-account-modal {
    font-family: 'Trebuchet MS', 'Verdana';
    width: 550px;
    border-radius: 16px;
}
</style>
<style scoped>
.p-inputtext {
    width: 100% !important;
}

.nickname-input-container {
    display: flex;
    flex-direction: column;
    padding-top: 16px;
    margin-bottom: 16px;
}

.currency-input-container {
    padding-top: 8px;
}

.currency-input-container .p-dropdown {
    width: 100%;
    height: 36px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.nickname-input-container .p-inputtext {
    font-size: 16px;
    width: 100%;
    padding: 8px 8px 8px 16px;
    height: 36px;
    color: black;
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.nickname-input-container .p-inputtext:enabled:hover {
    border-color: #33CC99;
}

.nickname-input-container .p-inputtext:enabled:focus {
    border-color: #33CC99;
}

.nickname-input-container>label {
    font-size: 16px;
    padding-left: 16px;
    color: #BFBFBF;
}
</style>